import { useNavigate } from 'react-router-dom';
import { Menu, Avatar } from 'antd';
import { UserOutlined, LogoutOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

function UserMenu() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        // <Menu mode="horizontal">
                <SubMenu key="SubMenu" icon={<Avatar icon={<UserOutlined />} style={{ background: 'transparent' }}/>} theme='light'>
                    <Menu.Item key="signup" icon={<UserAddOutlined />}>
                        <Link to="/users">Users</Link>
                    </Menu.Item>
                    <Menu.Item key="logout" icon={<LogoutOutlined />}>
                        <Link to="/logout" onClick={handleLogout}>Logout</Link>
                    </Menu.Item>
                </SubMenu>
        // </Menu>
    );
}

export default UserMenu;