import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, notification } from 'antd';
import { resetPassword } from '../api'; // Import the resetPassword function
import { useNavigate } from 'react-router-dom'; // Import Link

function ResetPassword() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');

    useEffect(() => {
        // Get the reset token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const resetToken = urlParams.get('token');
        setToken(resetToken);
    }, []);

    const handleSubmit = () => {
        resetPassword(token, password)
            .then(() => {
                notification.success({
                    message: 'Password Reset Successful',
                    description: 'Your password has been reset. You can now login with your new password.',
                });
                navigate('/login');
            })
            .catch(error => {
                notification.error({
                    message: 'Error',
                    description: error.response.data.message,
                });
            });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 300 }}>
                <h1 style={{ textAlign: 'center' }}>Reset Password</h1>
                <Form onFinish={handleSubmit}>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your new password!' }]}
                    >
                        <Input.Password placeholder="New Password" value={password} onChange={e => setPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default ResetPassword;