import '../App.css';
import withAuth from '../withAuth';
import React, { useState, useEffect, useRef } from 'react';
import { socket } from '../socket';
import { Layout, List, Input, Button, Avatar } from 'antd';
import { UserOutlined, SendOutlined, CalendarOutlined, RobotOutlined } from '@ant-design/icons';
import { getConversations, getMessages } from '../api';

const { Sider, Content } = Layout;

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [conversations, setConversations] = useState([]); // Replace with your actual conversations
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        socket.on('message', (message) => {
            if(message.conversationId === selectedConversation){
                setMessages((messages) => [...messages, message]);
            }
            // Check if there is any conversation with conversationId === message.conversationId if not add to the conversation list
            if(!conversations.find(conversation => conversation._id === message.conversationId)){
                setConversations([...conversations, { _id: message.conversationId, name: message.name, assistant: { name: message.assistant.name } }]);
            } else {
                // If there is a conversation with conversationId === message.conversationId, update the conversation with the new message
                setConversations(conversations.map(conversation => {
                    if(conversation._id === message.conversationId){
                        conversation.unread = true;
                    }
                    return conversation;
                }));
            }
        });
        return () => {
            socket.off('message');
        };
    }, [selectedConversation, conversations]);

    useEffect(() => {
        getConversations()
            .then(response => {
                if(response.data.length > 0){
                    setConversations(response.data);
                }
            }).catch(error => {
                console.error('Error:', error);
            });
    }, []);

    useEffect(() => {
        const storedConversation = localStorage.getItem('selectedConversation');
        if (storedConversation) {
            setSelectedConversation(JSON.parse(storedConversation));
        } else if (conversations.length > 0) {
            setSelectedConversation(conversations[0]._id);
            localStorage.setItem('selectedConversation', JSON.stringify(conversations[0]._id));
        }
    }, [conversations]);

    useEffect(() => {
        if (selectedConversation) {
            getMessages(selectedConversation)
                .then(response => {
                    if(response.data.length > 0) {
                        setMessages(response.data); // Update your state with the received messages
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [selectedConversation]);

    const handleSend = () => {
        const message = { sender: 'User1', content: newMessage };
        socket.emit('message', message); // Send the message to the server
        setMessages([...messages, message]);
        setNewMessage('');
    };

    const handleConversationClick = (conversation) => {
        // remove unread class
        setConversations(conversations.map(c => {
            if(c._id === conversation._id){
                c.unread = false;
            }
            return c;
        }));
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <Layout style={{ display: 'flex', flex: 1, height: 'calc(100vh - 50px)', backgroundColor: '#fff'}}>
            <Sider width={400} style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <List 
                style={{ 
                    padding: '10px', 
                    flexGrow: 1, // Make the list grow to take up the remaining space
                    overflowY: 'auto' // Enable vertical scrolling
                }}
                itemLayout="horizontal"
                dataSource={conversations}
                renderItem={conversation => (
                    <List.Item 
                        className={`conversation ${conversation.unread ? 'unread' : ''}`}
                        onClick={() => {
                            handleConversationClick(conversation);
                            setSelectedConversation(conversation._id);
                            localStorage.setItem('selectedConversation', JSON.stringify(conversation._id));
                        }}
                        style={{ 
                            cursor: 'pointer', 
                            backgroundColor: selectedConversation === conversation._id ? '#f0f0f0' : '#fff' // Changes the background color of the selected item
                        }}
                    >
                        <List.Item.Meta
                            style={{ alignItems: 'center', padding: '0px 10px'}}
                            avatar={<Avatar icon={<UserOutlined />} />} // replace with the correct avatar URL
                            title={conversation.name}
                            description={conversation.assistant.name}
                        />
                    </List.Item>
                )}
            />
            </Sider>
            <Layout style={{display: 'flex', flex: 1}}>
                <Content style={{padding: '1px', flex: 1, height: '100%'}}> {/* Subtract the height of the top menu */}
                    <div style={{ display: 'flex', flex: 1, backgroundColor: '#fff', height: 'calc(100vh - 50px)', justifyContent: 'space-between', flexDirection: 'column' }}>
                        <div style={{ flex: 1, overflowY: 'auto', padding: '10px', backgroundColor: '#fafafa' }}>
                            <List
                                className='message-list-container'
                                itemLayout="horizontal"
                                dataSource={messages}
                                renderItem={message => (
                                    <List.Item className={`message ${message.role}`} style={{ border: 'none'}}>
                                        <div style={{marginRight: '10px'}} className={`chat-avatar ${message.role}`}>
                                            {message.role === 'assistant' ? <Avatar icon={<RobotOutlined/>} /> : <Avatar icon={<UserOutlined/>} />}
                                        </div>
                                        <div>
                                            <p className="message-content">{message.message}</p>
                                            {/* <p className="date">{new Date(message.dateTime).toLocaleString()}</p> */}
                                            {
                                                message.role !== 'action'  ?
                                                <p className="date">{ message.role === 'assistant' ?  `${message?.model} Tokens: ${message.usage?.total_tokens} `  : null }  <CalendarOutlined /> {new Date(message.dateTime).toLocaleString()}</p>
                                                : null
                                            }
                                        </div>
                                    </List.Item>
                                )}
                            />
                            <div ref={messagesEndRef} />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 40px'}}>
                            <Input.TextArea
                                value={newMessage}
                                disabled={true}
                                onChange={e => setNewMessage(e.target.value)}
                                placeholder="Type a message"
                                style={{ height: '60px', fontSize: '18px', marginTop: '20px', marginBottom: '20px'}}
                            />
                            <Button type="primary" onClick={handleSend} style={{ height: '60px', fontSize: '18px', marginLeft: '10px' }} disabled={true}>
                                <SendOutlined />
                            </Button>
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default withAuth(Chat);