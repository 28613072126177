import React, { useEffect, useState } from 'react';
import withAuth from '../../withAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { editUser, deleteUser, getUser } from '../../api';
import { Form, Input, Button, Card, notification, Checkbox, Space } from 'antd';

const EditUser = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser(id).then(user => {
            delete user.password; // Remove the password from the user object
            setUser(user);
        });
    }, [id]);

    const handleSubmit = (values) => {
        const { firstName, lastName, email, phone, username, password, isAdmin } = values;
        editUser(id, firstName, lastName, email, phone, username, password, isAdmin)
            .then(response => {
                navigate('/users');
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Edit User Failed',
                    description: 'There was an error while editing the account. Please try again.',
                });
            });
    };

    const handleDelete = () => {
        deleteUser(id)
            .then(response => {
                navigate('/users');
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Delete User Failed',
                    description: 'There was an error while deleting the account. Please try again.',
                });
            });
    };

    return user ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 600 }}>
                <h1 style={{ textAlign: 'center' }}>Edit User</h1>
                <Form initialValues={user} onFinish={handleSubmit}>
                <Form.Item
                        name="firstName"
                        rules={[
                            { required: true, message: 'Please input your first name!' },
                            { min: 2, message: 'First name must be at least 2 characters' },
                            { max: 30, message: 'First name must be at most 30 characters' },
                        ]}
                    >
                        <Input placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        rules={[
                            { required: true, message: 'Please input your last name!' },
                            { min: 2, message: 'Last name must be at least 2 characters' },
                            { max: 30, message: 'Last name must be at most 30 characters' },
                        ]}
                    >
                        <Input placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'The input is not valid E-mail!' },
                        ]}
                    >
                        <Input placeholder="Email" disabled/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={[
                            { required: true, message: 'Please input your phone number!' },
                            { pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/, message: 'Invalid phone number' },
                        ]}
                    >
                        <Input placeholder="Phone Number" />
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[
                            { required: true, message: 'Please input your username!' },
                            { min: 5, message: 'Username must be at least 5 characters' },
                            { max: 20, message: 'Username must be at most 20 characters' },
                        ]}
                    >
                        <Input placeholder="Username" disabled/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            { min: 8, message: 'Password must be at least 8 characters' },
                        ]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item name="isAdmin" valuePropName="checked">
                        <Checkbox>Is Admin</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Button type="primary" htmlType="submit">
                                Save Changes
                            </Button>
                            <Button onClick={handleDelete} type="danger">
                                Delete User
                            </Button>
                            <Button onClick={() => navigate('/users')}>
                                Cancel
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    ) : null;
};

export default withAuth(EditUser);