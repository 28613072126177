import React, { useEffect, useState } from 'react';
import withAuth from '../../withAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { editAssistant, deleteAssistant, getAssistant, deleteAction } from '../../api';
import { Form, Input, Button, Card, notification, Space, Row, Col, List, Avatar, Modal, Select } from 'antd';
import { ThunderboltOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ChatUI from '../chatUI';


const EditAssistant = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [assistant, setAssistant] = useState(null);
    const modelOptions = ['gpt-3.5-turbo-0125', 'gpt-3.5-turbo-16k', 'gpt-4', 'Gemini'];
    const languageOptions = ['English', 'Spanish'];
    const { Option } = Select;
    const [actions, setActions] = useState([]); // Add this line


    useEffect(() => {
        getAssistant(id).then(assistant => {
            setAssistant(assistant);
            setActions(assistant?.actions); // Add this line
        });
    }, [id]);

    const handleSubmit = (values) => {
        const { name, instructions, model, language, temperature, webhookUrl } = values;
        editAssistant(id, name, instructions, model, language, temperature, webhookUrl)
            .then(response => {
                navigate('/assistants');
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Edit Assistant Failed',
                    description: 'There was an error while editing the assistant. Please try again.',
                });
            });
    };

    const handleDelete = () => {
        Modal.confirm({
            title: 'Are you sure you want to delete this Assistant?',
            content: 'This action cannot be undone. All actions associated with this assistant will also be deleted.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteAssistant(id)
                    .then(response => {
                        navigate('/assistants');
                    })
                    .catch(error => {
                        console.log(error);
                        notification.error({
                            message: 'Delete Assistant Failed',
                            description: 'There was an error while deleting the assistant. Please try again.',
                        });
                    });
                },
            });
    };

    const handleDeleteAction = (actionId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this action?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteAction(actionId)
                    .then(response => {
                        setActions(actions.filter(action => action.id !== actionId));
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
        });
    }

    return assistant ? (
        <Row gutter={16} style={{ display: 'flex', alignItems: 'flex-start', marginTop: '2%'}}>
            <Col span={8} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Card style={{width: '80%'}}>
                    <h1>Edit Assistant</h1>
                    <Form initialValues={assistant} onFinish={handleSubmit}>
                        <Row gutter={16}>
                            <Col span={16}>
                                <Form.Item name="name" rules={[{ required: true, message: 'Please input the assistant name!' }]}>
                                    <Input placeholder="Assistant Name" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="language" rules={[{ required: true, message: 'Please select a language!' }]}>
                                    <Select placeholder="Select a language">
                                        {languageOptions.map(option => <Option key={option} value={option}>{option}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>           
                        <Form.Item name="instructions" rules={[{ required: true, message: 'Please input the instructions!' }]}>
                            <Input.TextArea placeholder="Instructions" style={{ height: '40vh' }} />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="model" rules={[{ required: true, message: 'Please select a model!' }]}>
                                    <Select placeholder="Select a model">
                                        {modelOptions.map(option => <Option key={option} value={option}>{option}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="temperature" rules={[{ required: true, message: 'Please input the GPT temperature!' }]}>
                                    <Input type="float" placeholder="GPT Temperature" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="webhookUrl">
                            <Input placeholder="Message Webhook URL" />
                        </Form.Item>
                        <Form.Item>
                            <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Button type="primary" danger="true" icon={<DeleteOutlined />}  onClick={handleDelete}>
                                    Delete
                                </Button>
                                <div style={{display: 'flex'}}>
                                    <Button type="primary" htmlType="submit" style={{marginRight: '10px'}}>
                                        Save
                                    </Button>
                                    <Button onClick={() => navigate('/assistants')}>
                                        Cancel
                                    </Button>
                                </div>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={8}>
                <Card style={{width: '100%'}}>
                        <h1 style={{ textAlign: 'left' }}>Chat</h1>
 
                    <ChatUI assistant={assistant} />
                </Card>
            </Col>
            <Col span={8}>
                <Card style={{width: '80%'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                        <h1 style={{ textAlign: 'left' }}>Actions</h1>
                        <Button type="primary" style={{ marginBottom: 16 }} onClick={() => navigate(`/assistants/edit/${id}/actions/create`)}>Add New Action</Button>
                    </div>
                    <List
                        itemLayout="horizontal"
                        dataSource={actions}
                        renderItem={action => (
                            <List.Item
                                actions={[
                                    <Button type="primary" icon={<EditOutlined />} onClick={() => navigate(`/assistants/edit/${id}/actions/edit/${action.id}`)}></Button>,
                                    <Button danger icon={<DeleteOutlined />} onClick={() => handleDeleteAction(action.id)}></Button>
                                ]}
                            >
                                <List.Item.Meta
                                    style={{ alignItems: 'center' }}
                                    avatar={<Avatar icon={<ThunderboltOutlined />}  style={{ background: '#f97d50', color: '#fff' }}/>}
                                    title={action.name}
                                    description={
                                        <>
                                            <div>{`id: ${action.id}`}</div>
                                            {/* <div>{`${action.trigger ? action.trigger : ''}`}</div> */}
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Col>
        </Row>
    ) : null;
};

export default withAuth(EditAssistant);