import React, { useEffect, useState } from 'react';
import { verifyEmail } from '../api';
import { Card, notification } from 'antd'; // Import Card and notification from antd
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function VerifyEmail() {
    const [message, setMessage] = useState('');
    const [isVerified, setIsVerified] = useState(false); // Add a new state variable

    useEffect(() => {
        // Get the email token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const emailToken = urlParams.get('token');

        // Call the verify email API
        verifyEmail(emailToken)
            .then(response => {
                setMessage(response.data.message);
                setIsVerified(true); // Set isVerified to true
                notification.success({
                    message: 'Verification Successful',
                    description: response.data.message,
                });
            })
            .catch(error => {
                setMessage(error.response.data.message);
                notification.error({
                    message: 'Verification Failed',
                    description: error.response.data.message,
                });
            });
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 300 }}>
                <h1 style={{ textAlign: 'center' }}>Email Verification</h1>
                <p style={{ textAlign: 'center' }}>{message}</p>
                {isVerified && <p style={{ textAlign: 'center' }}><Link to="/login">Go to Login</Link></p>} {/* Add a link to the login page */}
            </Card>
        </div>
    );
}

export default VerifyEmail;