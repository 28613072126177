import { Card } from 'antd';
import { Link } from 'react-router-dom';

function PasswordResetEmailSent() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'transparent' }}>
            <Card style={{ width: 300 }}>
                <h1 style={{ textAlign: 'center' }}>Reset Password Instructions Sent</h1>
                <p style={{ textAlign: 'center' }}>We've sent an email to your address with instructions on how to reset your password. Please check your email and follow the provided steps.</p>
                <p style={{ textAlign: 'center' }}><Link to="/login">Back to Login</Link></p>
            </Card>
        </div>
    );
}

export default PasswordResetEmailSent;