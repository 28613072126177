import React from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const HomePage = () => {
    const isUserLoggedIn = localStorage.getItem('token') ? true : false;

    return (
        <div className="landing-page">
            <Row style={{ height: '300px', background: 'url(/path/to/your/banner.jpg)', backgroundSize: 'cover' }}>
                <Col span={24}>
                    <Title style={{ color: '#fff', textAlign: 'center', paddingTop: '100px' }}>Catchy Slogan Here</Title>
                </Col>
            </Row>
            <Row justify="center" align="middle" style={{ minHeight: '100vh', background: '#f5f8fa' }}>
                <Col span={12}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '50px' }}>
                        <Title level={2}>OurBusiness</Title>
                        {isUserLoggedIn ? (
                            <Link to="/dashboard">Dashboard</Link>
                        ) : (
                            <Link to="/login">Login</Link>
                        )}
                    </div>
                    <Card style={{ textAlign: 'center', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                        <Title>Welcome to Our Business!</Title>
                        <Paragraph>
                            We provide the best chat widget service for your website. Our chat widget is easy to integrate, highly customizable, and provides a seamless experience for your users.
                        </Paragraph>
                        <Button type="primary" size="large">Get Started</Button>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default HomePage;