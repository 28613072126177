import React from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link
import { signup } from './../api';
import { Form, Input, Button, Card, notification } from 'antd'; 

const Signup = () => {
    const navigate = useNavigate();

    const handleSubmit = (values) => {
        const { firstName, lastName, email, phone, username, password, subdomain } = values;
        signup(firstName, lastName, email, phone, username, password, subdomain)
            .then(response => {
                console.log(response);
                navigate('/email-sent');
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Signup Failed',
                    description: error.response.data.message,
                });
            });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 300 }}>
                <h1 style={{ textAlign: 'center' }}>Signup</h1>
                <Form onFinish={handleSubmit}>
                    <Form.Item
                        name="firstName"
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                    >
                        <Input placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        rules={[{ required: true, message: 'Please input your last name!' }]}
                    >
                        <Input placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input placeholder="Phone Number" />
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    {/* <Form.Item
                        name="subdomain"
                        rules={[{ required: true, message: 'Please input your subdomain!' }]}
                    >
                        <Input placeholder="Subdomain" />
                    </Form.Item> */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Signup
                        </Button>
                    </Form.Item>
                </Form>
                <p style={{ textAlign: 'center' }}>
                    Already have an account? <Link to="/login">Login</Link>
                </p>
            </Card>
        </div>
    );
};

export default Signup;