import React from 'react';
import { Card } from 'antd';

const WebhookPage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '50px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h1 style={{ textAlign: 'left' }}>Webhook Integration</h1>
            </div>
            <Card style={{ width: '100%' }} >
                <p>To set up your own Webhook, you need an API key from OpenAI. You can get this key from the OpenAI website.</p>
            </Card>
        </div>
    );
};

export default WebhookPage;