import React from 'react';
import { Card } from 'antd'; // Import Card from antd
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function EmailSent() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 300 }}>
                <h1 style={{ textAlign: 'center' }}>Email Sent</h1>
                <p style={{ textAlign: 'center' }}>An email has been sent to your address. Please check your email and verify your account.</p>
                <p style={{ textAlign: 'center' }}><Link to="/login">Go to Login</Link></p> {/* Add a link to the login page */}
            </Card>
        </div>
    );
}

export default EmailSent;