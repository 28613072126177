import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

function NotAuthorized() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 300 }}>
                <h1 style={{ textAlign: 'center' }}>Not Authorized</h1>
                <p style={{ textAlign: 'center' }}>You do not have permission to access this page.</p>
                <p style={{ textAlign: 'center' }}><Link to="/">Go to Home</Link></p>
            </Card>
        </div>
    );
}

export default NotAuthorized;