import React, { useEffect } from 'react';

const ChatWidgetPreview = ({ website, chatWidgetId, widgetUpdated, websiteUpdated }) => {
    useEffect(() => {
        const container = document.getElementById('chat-widget-container');
        container.innerHTML = '';

        const chatWidgetIframe = document.createElement('iframe');
        chatWidgetIframe.id = 'chat-widget-iframe';
        chatWidgetIframe.style.border = 'none';
        chatWidgetIframe.style.width = '100%';
        chatWidgetIframe.style.height = '100%';
        chatWidgetIframe.style.position = 'absolute';
        chatWidgetIframe.style.bottom = '0px';
        chatWidgetIframe.style.right = '0px';
        container.appendChild(chatWidgetIframe);

        chatWidgetIframe.srcdoc = `
            <!DOCTYPE html>
            <html>
            <head>
                <title>Chat Widget</title>
            </head>
            <body>
                <script src="${process.env.REACT_APP_BASE_URL}/widget/embed/${chatWidgetId}" async></script>
            </body>
            </html>
        `;

        return () => {
            const widgetContainer = document.getElementById('chat-widget-container');
            if (widgetContainer) {
                widgetContainer.innerHTML = '';
            }
        };
    }, [chatWidgetId, widgetUpdated]);

    return (
        <div style={{flex: '1', height: '100%', overflow: 'hidden'}}>
            <div id="chat-widget-container" style={{display: 'flex', justifyContent: 'end', alignContent: 'end'}} />
        </div>
    );
};

export default ChatWidgetPreview;