import React, { useEffect, useState } from 'react';
import withAuth from '../../withAuth';
import { getActions } from '../../api'; // You'll need to implement this function
import { List, Card, Button } from 'antd';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const ActionsList = () => {
    const [actions, setActions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getActions()
            .then(response => {
                setActions(response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    const navigateToCreateAction = () => {
        navigate('/actions/create'); // You'll need to create this route
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '50px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h1 style={{ textAlign: 'left' }}>All Actions</h1>
                <Button type="primary" icon={<UserAddOutlined />} onClick={navigateToCreateAction}>
                    Create Action
                </Button>
            </div>
            <Card style={{ width: '100%' }}>
            <List
                itemLayout="horizontal"
                dataSource={actions}
                renderItem={action => (
                    <List.Item
                        actions={[
                            <Button 
                                icon={<EditOutlined />} 
                                onClick={() => navigate(`/actions/edit/${action._id}`)} // You'll need to create this route
                            >
                                Edit
                            </Button>
                        ]}
                    >
                        <List.Item.Meta
                            title={action.name}
                            description={`Action Id: ${action._id}`}
                        />
                    </List.Item>
                )}
            />
            </Card>
        </div>
    );
};

export default withAuth(ActionsList);