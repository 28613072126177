import React, { useEffect, useState } from 'react';
import withAuth from '../../withAuth';
import { getUsers } from '../../api';
import { List, Avatar, Card, Button } from 'antd';
import { UserOutlined, UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getUsers()
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    const navigateToCreateUser = () => {
        navigate('/users/create');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '50px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h1 style={{ textAlign: 'left' }}>All Users</h1>
                {localStorage.getItem('isAdmin') === 'true' ? (
                    <Button type="primary" icon={<UserAddOutlined />} onClick={navigateToCreateUser}>
                        Create User
                    </Button>
                ) : null}
            </div>
            <Card style={{ width: '100%' }}>
            <List
                itemLayout="horizontal"
                dataSource={users}
                renderItem={user => (
                        <List.Item
                            actions={[
                                localStorage.getItem('isAdmin') === 'true' ? (
                                    <Button 
                                        icon={<EditOutlined />} 
                                        onClick={() => navigate(`/users/edit/${user._id}`)}
                                    >
                                        Edit
                                    </Button>
                                ) : null
                            ]}
                        >
                        <List.Item.Meta
                            style={{ alignItems: 'center' }}
                            avatar={<Avatar icon={<UserOutlined />} />}
                            title={`${user.firstName} ${user.lastName}`}
                            description={user.email}
                        />
                    </List.Item>
                )}
            />
            </Card>
        </div>
    );
};

export default withAuth(UsersList);