import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Card, Switch, Row, Col, Checkbox } from 'antd';
import { saveGeminiSettings, getGeminiSettings } from '../../api';

const GeminiPage = () => {
    const [apiKey, setApiKey] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [checkedModels, setCheckedModels] = useState([]);

    useEffect(() => {
        // Retrieve the Gemini settings when the component mounts
        getGeminiSettings()
            .then(response => {
                // Handle the response data
                if(response.data) {
                    const { apiKey, isEnabled, models } = response.data;
                    setApiKey(apiKey);
                    setIsEnabled(isEnabled);
                    setCheckedModels(models);
                }
            })
            .catch(error => {
                // Handle the error
                console.error('Error:', error);
                message.error('An error occurred while retrieving the settings.');
            });
    }, []);

    const handleSave = () => {
        // Send the settings to the server
        saveGeminiSettings({ isEnabled, apiKey, models: checkedModels })
            .then(response => {
                // Handle the response data
                console.log(response.data);
                message.success('Settings saved successfully!');
            })
            .catch(error => {
                // Handle the error
                console.error('Error:', error);
                message.error('An error occurred while saving the settings.');
            });
    };

    const handleModelChange = checkedValues => {
        setCheckedModels(checkedValues);
    };

    return (
        <div style={{ padding: '50px' }}>
            <h1 style={{ textAlign: 'left' }}>Gemini Integration</h1>
            <Row gutter={16}>
                <Col span={12}>
                    <Card>
                        <Form>
                            <Form.Item label="Enable Integration">
                                <Switch checked={isEnabled} onChange={setIsEnabled} />
                            </Form.Item>
                            <Form.Item label="API Key" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Input value={apiKey} onChange={e => setApiKey(e.target.value)} disabled={!isEnabled} />
                            </Form.Item>
                            <Form.Item label="Enable Models" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Checkbox.Group style={{ width: '100%' }} value={checkedModels} onChange={handleModelChange} disabled={!isEnabled}>
                                    <Row>
                                        <Col span={24}><Checkbox value="gpt-4-0125-preview">gpt-4-0125-preview</Checkbox></Col>
                                        <Col span={24}><Checkbox value="gpt-4">gpt-4</Checkbox></Col>
                                        <Col span={24}><Checkbox value="gpt-3.5-turbo-0125">gpt-3.5-turbo-0125</Checkbox></Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Button type="primary" onClick={handleSave}>Save</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                    <p><strong>Access OpenAI's Website:</strong><br/> Begin by navigating to the <a href="https://openai.com" target="_blank" rel="noopener noreferrer">official OpenAI website</a> using your preferred web browser.</p>
                    <p><strong>Account Creation or Login:</strong><br/> If you already have an OpenAI account, proceed to log in with your credentials. If not, you'll need to sign up. Click on the "Sign Up" option and follow the prompts to create a new account.</p>
                    <p><strong>Navigate to the API Section:</strong><br/> Once logged in, you'll be directed to the app dashboard. From here, locate and select the "API" option to proceed.</p>
                    <p><strong>Accessing API Keys:</strong><br/> In the API section, look for the menu on the left side of the screen. Click on "API Keys" to view your API key management area.</p>
                    <p><strong>Create a New API Key:</strong><br/> Within the API Keys section, find and click on the "Create a New Secret Key" button. This action will initiate the process of generating a new API key.</p>
                    <p><strong>Naming Your API Key:</strong><br/> A prompt will appear asking you to name your new API key. Choose a name that is descriptive and meaningful to you. This will help you identify the key's purpose or project it's associated with later on.</p>
                    <p><strong>Finalizing Your API Key:</strong><br/> After naming your key, click on the "Create" button to generate your new secret API key.</p>
                    <p><strong>Secure Your API Key:</strong><br/> Immediately upon creation, your new API key will be displayed. It's crucial to copy this key to a secure location. Remember, once you close this popup window, the key will not be displayed again for security reasons.</p>
                    <p><strong>Utilizing Your API Key:</strong><br/> Finally, paste your newly obtained API key into the designated "API Key" field in your application or project where it's required. This will authenticate your requests to OpenAI's services.</p>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default GeminiPage;