import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from './api';

const withAuth = (ComponentToProtect) => {
    return (props) => {
        const navigate = useNavigate();
        const [loading, setLoading] = useState(true);
        const [redirect, setRedirect] = useState(false);

        useEffect(() => {
            isTokenValid()
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    setRedirect(true);
                });
        }, []);

        if (loading) {
            return null;
        }
        if (redirect) {
            localStorage.removeItem('token');
            navigate('/login');
        }
        return <ComponentToProtect {...props} />;
    }
}

export default withAuth;