import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Select, Button, message, Card, Row, Col, Input, Switch, Modal } from 'antd';
import { addChatWidget, getChatWidget, updateChatWidget, deleteChatWidget } from '../../api';
import { DeleteOutlined, SaveOutlined, ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons';
import { getAssistants } from '../../api'; // replace with your actual API call
import ChatWidgetPreview from './Preview'; // replace with your actual component
import copy from 'copy-to-clipboard';

// import { debounce } from 'lodash';

const { Option } = Select;

const CreateChatWidgetPage = () => {
    // const [color, setColor] = useState('#ffffff');
    // const [width, setWidth] = useState('300');
    // const [height, setHeight] = useState('600');
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [assistants, setAssistants] = useState([]);
    // const [selectedAssistant, setSelectedAssistant] = useState(null);
    const [widgetUpdated, setWidgetUpdated] = useState(false);
    const [website, setWebsite] = useState('');
    // const [iframeSrc, setIframeSrc] = useState('');

    // const [websiteUpdated, setWebsiteUpdated] = useState(false);
    // const debouncedSetWebsite = debounce(setWebsite, 300);
    const [chatWidgetId, setChatWidgetId] = useState(null);
    const [embedScript, setEmbedScript] = useState(''); // Add this line


    const { id } = useParams();

    useEffect(() => {
        if (id === 'create') {
            form.resetFields();
        } else {
            // Load the existing configuration from the database
            getChatWidget(id)
                .then(response => {
                    // Set the form values
                    form.setFieldsValue(response.data);

                    // Update chatWidgetId and website
                    setChatWidgetId(response.data._id);
                    const firstWebsite = response.data.website.split(',')[0].trim();
                    setWebsite(firstWebsite)
                    setEmbedScript(`<script src="${process.env.REACT_APP_BASE_URL}/widget/embed/${response.data._id}"></script>`)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [widgetUpdated, form, id]); // Empty array means this effect runs once on component mount

    useEffect(() => {
        getAssistants()
            .then(response => {
                setAssistants(response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    // useEffect(() => {
    //     const iframe = document.createElement('iframe');
    //     iframe.onload = () => {
    //         console.log('Website loaded: ', website);
    //         setIframeSrc(website);
    //     };
    //     iframe.onerror = () => {
    //         console.log('Website not loaded: ', website);
    //         setIframeSrc('https://consumerlaw.com');
    //     };
    //     iframe.src = website;
    //     document.body.appendChild(iframe);
    //     return () => document.body.removeChild(iframe);
    // }, [website]);

    // const handleSelectAssistant = value => {
    //     setSelectedAssistant(value);
    // };

    const handleUpdate = values => {
        setWidgetUpdated(!widgetUpdated);
        // setWebsiteUpdated(!websiteUpdated);
        updateChatWidget(chatWidgetId, values)
            .then(response => {
                message.success('Chat widget configuration updated successfully');
            })
            .catch(error => {
                console.error('Error:', error);
                message.error('Failed to update chat widget configuration');
            });
    };

    const handleAdd = values => {
        setWidgetUpdated(!widgetUpdated);
        // setWebsiteUpdated(!websiteUpdated);
        addChatWidget(values)
            .then(response => {
                message.success('Chat widget configuration created successfully');
                navigate(`/integrations/chatwidget/${response.data.chatWidgetId}`);
            })
            .catch(error => {
                console.error('Error:', error);
                message.error('Failed to create chat widget configuration');
            });
    };

    const handleDelete = () => {
        Modal.confirm({
            title: 'Are you sure you want to delete this Widget?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteChatWidget(chatWidgetId)
                    .then(response => {
                        navigate(`/integrations/chatwidget`);
                        message.success('Chat widget configuration deleted successfully');
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        message.error('Failed to delete chat widget configuration');
                    });
            },
        });
    };

    // const handleColorChange = e => {
    //     setColor(e.target.value);
    // };

    // const handleWidthChange = e => {
    //     setWidth(e.target.value);
    // };

    // const handleHeightChange = e => {
    //     setHeight(e.target.value);
    // };

    const handleWebsiteChange = e => {
        const firstWebsite = e.target.value.split(',')[0].trim();
        setWebsite(firstWebsite)
    };

    const handleCopy = () => {
        copy(embedScript);
        message.success('Embed URL copied to clipboard!');
    };

    return (
        <div style={{ padding: '50px' }}>
            <Button type="link" onClick={() => navigate('/integrations/chatwidget')} style={{ marginBottom: '20px' }}>
                <ArrowLeftOutlined /> Go Back
            </Button>            
            <h1 style={{ textAlign: 'left' }}>
                {id === 'create' ? 'Create Chat Widget' : 'Update Chat Widget'}
            </h1>            
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card>
                        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Settings</h2>
                        <Form form={form} onFinish={id === 'create' ? handleAdd : handleUpdate}>
                            <Form.Item 
                                name="name" 
                                label="Name" 
                                labelCol={{ span: 6 }}  
                                style={{ marginBottom: '30px' }}
                                rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item 
                                name="assistantId" 
                                label="Select Assistant" 
                                labelCol={{ span: 6 }} 
                                style={{ marginBottom: '30px' }}
                                rules={[{ required: true, message: 'Please select an assistant!' }]}
                            >
                                <Select>
                                    {assistants.map(assistant => (
                                        <Option key={assistant._id} value={assistant._id}>
                                            {assistant.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item 
                                name="color" 
                                label="Color" 
                                labelCol={{ span: 6 }} 
                                style={{ marginBottom: '30px' }}
                                // onChange={handleColorChange}
                                rules={[{ required: true, message: 'Please select a color!' }]}
                            >
                                <Input type="color" />
                            </Form.Item>
                            <Form.Item 
                                name="width" 
                                label="Width" 
                                labelCol={{ span: 6 }} 
                                help="Set the width of the widget in pixels." 
                                style={{ marginBottom: '30px' }}
                                // onChange={handleWidthChange}
                                rules={[{ required: true, message: 'Please input the width!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item 
                                name="height" 
                                label="Height" 
                                labelCol={{ span: 6 }} 
                                help="Set the height of the widget in pixels." 
                                style={{ marginBottom: '30px' }}
                                // onChange={handleHeightChange}
                                rules={[{ required: true, message: 'Please input the height!' }]}
                            >
                                <Input />
                            </Form.Item>                            
                            <Form.Item 
                                name="website" 
                                label="Website" 
                                labelCol={{ span: 6 }} 
                                help="Enter the website URLs where the widget will be placed, separated by commas. You must include 'http://' or 'https://' in the URLs." 
                                style={{ marginBottom: '30px' }}
                                onChange={handleWebsiteChange}
                                rules={[{ required: true, message: 'Please input the website URL!' }]}
                            >
                                <Input 
                                    placeholder="Enter website URL"
                                    value={website} 
                                />
                            </Form.Item>
                            <Form.Item name="isOpenByDefault" label="Open by Default" valuePropName="checked" labelCol={{ span: 6 }}  style={{ marginBottom: '30px' }}>
                                <Switch />
                            </Form.Item>
                            <Form.Item 
                                label="Embed Code" 
                                labelCol={{ span: 6 }} 
                                style={{ marginBottom: '30px' }}
                                help="To embed the chat widget in your website, copy this code and paste it into your website's HTML, preferably right before the closing </body> tag."
                            >
                                <Input 
                                    addonAfter={<CopyOutlined onClick={handleCopy} />} 
                                    value={embedScript} 
                                    readOnly
                                />
                            </Form.Item>
                            <Form.Item style={{display: 'flex', justifyContent: 'end', marginTop: '80px'}}>
                                {id === 'create' ? (
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Save</Button>
                                ) : (
                                    <div>
                                        <Button danger onClick={handleDelete} icon={<DeleteOutlined />} style={{marginRight: '10px'}}>Delete</Button>
                                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Save</Button>
                                    </div>
                                )}
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card>
                        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Preview</h2>
                        <div id="preview-container" style={{display: 'flex', justifyContent: 'end', alignItems: 'end', height: '800px', width: '100%'}}>
                            <iframe title='preview'
                                src={website} 
                                style={{ width: `100%`, height: `100%`, border: 'none' }}
                            />
                            <div>
                                <ChatWidgetPreview website={website} chatWidgetId={chatWidgetId} widgetUpdated={widgetUpdated} websiteUpdated={null} />
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CreateChatWidgetPage;