import React, { useState } from 'react';
import withAuth from '../../withAuth';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { createAction } from '../../api';
import { Form, Input, Button, Card, notification, Space, Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const CreateAction = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [keywords, setKeywords] = useState([{ value: null }]);
    const { assistantId } = useParams();
    const path = location.pathname;
    const newPath = path.replace('/actions/create', '');

    const handleSubmit = (values) => {
        const { name, trigger, jsonObject, webhookURL } = values;
        createAction(name, trigger, keywords, jsonObject, webhookURL, assistantId)
            .then(response => {
                navigate(newPath);
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Create Action Failed',
                    description: `There was an error while creating the action. Please try again. ${error.response.data.message}`,
                });
            });
    };

    const handleCancel = () => {
        navigate(newPath);
    };

    const handleAddKeyWord = () => {
        const newInputs = [...keywords, { value: null }];
        setKeywords(newInputs);
    }
    
    const handleRemoveKeyWord = (i) => {
        const newInputs = [...keywords];
        newInputs.splice(i, 1);
        setKeywords(newInputs);
    }
    
    const handleChangeKeyWord = (i, event) => {
        const newInputs = [...keywords];
        newInputs[i].value = event.target.value;
        setKeywords(newInputs);
    }

    return (
        <Form onFinish={handleSubmit}>   
            <Row gutter={16} style={{ display: 'flex', alignItems: 'flex-start', marginTop: '2%'}}>
                <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Card style={{ width: '80%' }}>
                        <h1>Create Action</h1>
                            <Form.Item name="name" rules={[{ required: true, message: 'Please input the action name!' }]}>
                                <Input placeholder="Action Name" />
                            </Form.Item>
                            <Form.Item name="trigger" rules={[{ required: true, message: 'Please input the trigger!' }]}>
                                <Input.TextArea placeholder="Trigger" style={{ height: '20vh'}}/>
                            </Form.Item>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px'}}>
                                {keywords.map((input, i) => (
                                    <Form.Item key={i}>
                                        <div style={{width: '100%', display: 'flex'}}>
                                            <Input
                                                placeholder={`Keyword ${i + 1}`}
                                                value={input.value || ""}
                                                onChange={event => handleChangeKeyWord(i, event)}
                                            />
                                            <Button type="button" icon={<DeleteOutlined />} onClick={() => handleRemoveKeyWord(i)}/>
                                        </div>
                                    </Form.Item>
                                ))}
                                <Button onClick={handleAddKeyWord} outline="true">Add Keyword</Button>
                            </div>
                            <Form.Item>
                                <Space style={{ width: '100%', justifyContent: 'end' }}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                    <Button onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </Space>
                            </Form.Item>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card style={{ width: '80%' }}>
                        <h1>Additional Settings</h1>
                        <Form.Item name="jsonObject" rules={[{ required: true, message: 'Please input the JSON object!' }]}>
                            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }}  />
                        </Form.Item>
                        <Form.Item name="webhookURL" rules={[{ required: true, message: 'Please input the webhook URL!' }]}>
                            <Input placeholder="Webhook URL" />
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};

export default withAuth(CreateAction);