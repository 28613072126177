import React, { useEffect, useState } from 'react';
import { Card, Button, List, Avatar } from 'antd';
import { UserAddOutlined, EditOutlined, MessageOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getWidgets } from '../../api'; // replace with your actual API call

const ChatWidgetPage = () => {
    const [widgets, setWidgets] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getWidgets()
            .then(response => {
                console.log('Success:', response);
                setWidgets(response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    const navigateToCreateWidget = () => {
        navigate('/integrations/chatwidget/create');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '50px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h1 style={{ textAlign: 'left' }}>ChatWidgets</h1>
                {localStorage.getItem('isAdmin') === 'true' ? (
                    <Button type="primary" icon={<UserAddOutlined />} onClick={navigateToCreateWidget}>
                        Create Widget
                    </Button>
                ) : null}
            </div>
            <Card style={{ width: '100%' }}>
                <List
                    itemLayout="horizontal"
                    dataSource={widgets}
                    renderItem={widget => (
                        <List.Item
                            actions={[
                                localStorage.getItem('isAdmin') === 'true' ? (
                                    <Button 
                                        icon={<EditOutlined />} 
                                        onClick={() => navigate(`/integrations/chatwidget/${widget._id}`)}
                                    >
                                        Edit
                                    </Button>
                                ) : null
                            ]}
                        >
                            <List.Item.Meta
                                style={{ alignItems: 'center' }}
                                avatar={<Avatar icon={<MessageOutlined />} />}
                                title={`${widget.name}`}
                                description={`Id: ${widget._id}`}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    );
};

export default ChatWidgetPage;