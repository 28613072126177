import { Modal } from 'antd';

const ActionModal = ({ isVisible, handleOk, handleCancel, actionData }) => {
    const data = JSON.stringify(actionData, null, 2);
    return (
        <Modal title="Action Details" open={isVisible} onOk={handleOk} onCancel={handleCancel}>
            {/* Display action data here */}
            <p>{`Action: ${data}`}</p>
        </Modal>
    );
};

export default ActionModal;