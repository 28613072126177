import React from 'react';
import withAuth from '../../withAuth';
import { useNavigate } from 'react-router-dom';
import { createAssistant } from '../../api';
import { Form, Input, Button, Card, notification, Space, Row, Col, List } from 'antd';
import {  EditOutlined, DeleteOutlined } from '@ant-design/icons';


const CreateAssistant = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const actions = []; // Add this line

    const handleSubmit = (values) => {
        const { name, instructions } = values;
        createAssistant(name, instructions)
            .then(response => {
                navigate(`/assistants/edit/${response.data.assistantId}`);
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Create Assistant Failed',
                    description: 'There was an error while creating the assistant. Please try again.',
                });
            });
    };

    return (
        <Row gutter={16} style={{ display: 'flex', alignItems: 'flex-start', marginTop: '2%'}}>
            <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Card style={{width: '80%'}}>
                    <h1 style={{ textAlign: 'center' }}>Create Assistant</h1>
                    <Form form={form} onFinish={handleSubmit}>
                        <Form.Item name="name" rules={[{ required: true, message: 'Please input the assistant name!' }]}>
                            <Input placeholder="Assistant Name" />
                        </Form.Item>
                        <Form.Item name="instructions" rules={[{ required: true, message: 'Please input the instructions!' }]}>
                            <Input.TextArea placeholder="Instructions" style={{ height: '50vh' }} />
                        </Form.Item>
                        <Form.Item>
                            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Button type="primary" htmlType="submit">
                                    Save Changes
                                </Button>
                                <Button onClick={() => navigate('/assistants')}>
                                    Cancel
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={12}>
                <Card style={{width: '80%'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                        <h1 style={{ textAlign: 'left' }}>Actions</h1>
                        <Button type="primary" style={{ marginBottom: 16 }} disabled>Add New Action</Button>
                    </div>
                    <List
                        itemLayout="horizontal"
                        dataSource={actions}
                        renderItem={action => (
                            <List.Item
                                actions={[
                                    <Button type="primary" icon={<EditOutlined />} >Edit</Button>,
                                    <Button danger icon={<DeleteOutlined />}>Delete</Button>
                                ]}
                            >
                                <List.Item.Meta
                                    style={{ alignItems: 'center' }}
                                    title={action.name}
                                    description={`id: ${action.id}`}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default withAuth(CreateAssistant);