import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import Link
import { Card, Form, Input, Button, notification } from 'antd';
import { forgotPassword } from '../api'; // Import the forgotPassword function

function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleSubmit = () => {
        forgotPassword(email)
            .then(() => {
                notification.success({
                    message: 'Email Sent',
                    description: 'Please check your email for the password reset link.',
                });
                navigate('/passord-reset-email-sent');
            })
            .catch(error => {
                notification.error({
                    message: 'Error',
                    description: error.response.data.message,
                });
            });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 300 }}>
                <h1 style={{ textAlign: 'center' }}>Forgot Password</h1>
                <Form onFinish={handleSubmit}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Send Password Reset Email
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default ForgotPassword;