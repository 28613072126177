import React, { useEffect, useState } from 'react';
import withAuth from '../withAuth';
import { fetchDashboardData } from '../api'; // Import the fetchDashboardData function
import { DatePicker, Form, Layout, Card, Statistic, Row, Col } from 'antd'; // Import Ant Design components
import moment from 'moment';
// import SimpleBarChart from './barchart';
// import moment from 'moment';


const { Content } = Layout;
const { RangePicker } = DatePicker;

const Dashboard = () => {
    const [data, setData] = useState(null);
    // const [chartData, setChartData] = useState([]);
    // const [dates, setDates] = useState([]);
    // const [dates, setDates] = useState([moment().subtract(7, 'days'), moment()]);
    const [dates, setDates] = useState(
        (JSON.parse(localStorage.getItem('dates')) || []).map(date => moment(date))
    );
    
    useEffect(() => {
        const fetchData = async () => {
            const startDate = dates[0] ? new Date(dates[0]).toISOString() : null;
            const endDate = dates[1] ? new Date(dates[1]).toISOString() : null;
            const response = await fetchDashboardData(startDate, endDate);
            // setChartData(response);
            console.log(response);

            let result = {
                "action": {},
                "user": {
                    "total": 0,
                    "conversations": 0
                },
                "assistant": {
                    "total": 0,
                    "conversations": 0
                }
            };
            
            for (let date in response) {
                let data = response[date];
            
                result.user.total += data.user.total;
                result.user.conversations += data.user.conversations;
            
                result.assistant.total += data.assistant.total;
                result.assistant.conversations += data.assistant.conversations;
            
                for (let action in data.action) {
                    if (result.action[action]) {
                        result.action[action] += data.action[action];
                    } else {
                        result.action[action] = data.action[action];
                    }
                }
            }
            setData(result);
        };

        fetchData();
    }, [dates]); // Update the dashboard data whenever the date range changes

    const handleDatesChange = dates => {
        // dates ? dates : [];
        setDates(dates ? dates : []);
        localStorage.setItem('dates', JSON.stringify(dates ? dates : []));
    };

    const actions = data && data.action ? Object.entries(data.action) : [];
    const rows = Math.ceil(actions.length / 6);

    return (
        <Content style={{ padding: '50px' }}>
            <h1>Dashboard</h1>
            <Form layout="inline">
                <Form.Item label="Date Range">
                    <RangePicker onChange={handleDatesChange}  value={dates}/>
                </Form.Item>
            </Form>
            {data && (
                <Row gutter={16} style={{margin: '20px 0px'}}>
                    <Col span={8}>
                        <Card>
                            <Statistic title="Conversations" value={data.assistant?.conversations} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Statistic title="User Messages" value={data.user?.total} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Statistic title="Assistant Messages" value={data.assistant?.total} />
                        </Card>
                    </Col>
                </Row>
            )}
            {actions.length > 0 && [...Array(rows)].map((_, rowIndex) => (
                <Row gutter={16} key={rowIndex} style={{margin: '20px 0px'}}>
                    {actions.slice(rowIndex * 6, (rowIndex + 1) * 6).map(([message, count]) => (
                        <Col span={24 / Math.min(actions.length - rowIndex * 6, 6)} key={message}>
                            <Card style={{ backgroundColor: '#f0f0f0' }}> {/* Add this line */}
                                <Statistic title={message} value={count} />
                            </Card>
                        </Col>
                    ))}
                </Row>
            ))}
            {/* {chartData && (
                <Row gutter={16} style={{margin: '20px 0px'}}>
                    <Col span={8}>
                        <SimpleBarChart data={chartData} width={500} height={300} metric={'user'}/>
                    </Col>
                    <Col span={8}>
                        <SimpleBarChart data={chartData} width={500} height={300} metric={[{dataKey: 'user', fill: '#000'}, {dataKey: 'assistant', fill: 'red'}]}/>
                    </Col>
                    <Col span={8}>
                        <SimpleBarChart data={chartData} width={500} height={300} metric={'assistant'}/>
                    </Col>
                </Row>
            )} */}
            <div>
            </div>
        </Content>
    );
};

export default withAuth(Dashboard);